
  import { defineComponent, computed } from "vue"

  interface Props {
    data: any
  }

  export default defineComponent({
    props: {
      data: {
        type: Object,
        require: true
      },
    },
    setup(props: Props) {
      // console.log("mib: ", props.data)
      const icons = computed(() => props.data.field?.options?.icons)
      const iconAttributeValue = (icon) => {
        const key = props.data.field?.key+'.'+icon.key
        return (key.split('.').reduce((p,c)=>p&&p[c]||null, props.data.item))
      }
      const mappedIcons = (icon) => icon.mappedIcons

      const cellsMapping = (icon) => {
        return icon.mappedIcons.find(mapping => {
          return mapping.value === iconAttributeValue(icon)
        })
      }

      const cellStack = (icon) => cellsMapping(icon)?.stack

      const isIcon = (stackElement) => stackElement.prefix && stackElement.icon

      return {
        icons,
        iconAttributeValue,
        cellStack,
        isIcon
      }
    }
  })
